<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn slot="activator" class="" v-on="on" color="secondary" depressed
        >Create new Bot</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <h2>Create a new Bot</h2>
      </v-card-title>
      <v-card-text>
        <h3 class="subheading mt-3">1. Fill in your trading information</h3>
        <v-form class="px-3" ref="form">
          <v-text-field
            label="Bot title"
            v-model="botTitle"
            prepend-icon="mdi-folder"
            :rules="inputRules"
          ></v-text-field>
          <v-select
            :items="exchanges"
            label="Digital exchange"
            prepend-icon="mdi-warehouse"
            v-model="exchange"
          >
          </v-select>
          <v-text-field
            label="API-Key"
            v-model="apikey"
            prepend-icon="mdi-key"
            :rules="requiredRules"
          ></v-text-field>
          <v-text-field
            class="mb-1"
            label="API-Secret"
            v-model="apisecret"
            prepend-icon="mdi-secret"
            :rules="requiredRules"
          ></v-text-field>
          <v-divider class=""></v-divider>
          <h3 class="subheading mt-3">
            2. Choose a Bot or specify your trading algorithm
          </h3>
          <v-select
            :items="algorithms"
            label="Algorithm"
            prepend-icon="mdi-laptop"
            v-model="algorithm"
          >
          </v-select>
          <v-select
            :items="intervals"
            label="Trading interval"
            prepend-icon="mdi-clock"
            v-model="timeInterval"
          >
          </v-select>
          <v-divider class=""></v-divider>
          <h3 class="subheading mt-3">3. Choose your currency to trade</h3>
          <v-select
            :items="currencies"
            label="Trading currency"
            prepend-icon="mdi-currency-btc"
            v-model="asset"
          >
          </v-select>
          <v-text-field
            class="mb-1"
            label="Capital for trading in $"
            v-model="capital"
            prepend-icon="mdi-currency-usd"
            :rules="requiredRules"
            type="number"
          ></v-text-field>

          <v-spacer></v-spacer>

          <div class="text-center">
            <v-btn
              outlined
              color="primary"
              class="mx-0 mt-5"
              @click="createBot"
              :loading="loading"
              >Bot erstellen</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export default {
  data() {
    return {
      botTitle: "",
      capital: "",
      exchange: "",
      apikey: "",
      apisecret: "",
      algorithm: "",
      timeInterval: "",
      asset: "",
      subaccount: null,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      requiredRules: [(v) => (v && v.length > 0) || "This field is required"],
      loading: false,
      dialog: false,
      exchanges: ["Coinbase", "FTX", "Bitpanda", "Binance", "Kraken"],
      algorithms: ["pm7", "pm90", "pm7DT", "pm7pm15DT"],
      intervals: ["1h", "4h", "1d"],
      currencies: ["LTC", "UNI"],
    };
  },
  methods: {
    async createBot() {
      //checking for valid form
      if (this.$refs.form.validate()) {
        //saving to the database

        await this.postForm();
        this.dialog = false;
        this.$refs.form.reset();
        //emiting an event for the navbar component from the popup component
        this.$emit("botCreated");
      }
    },
    async postForm() {
      const token = this.$store.getters["user/getTokenValue"];
      let request = await fetch(this.createBotURL, {
        method: "POST",
        withCredentials: true,
        headers: {
          Authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botname: this.botTitle,
          exchange: this.exchange,
          apikey: this.apikey,
          apisecret: this.apisecret,
          subaccount: this.subaccount,
          algorithm: this.algorithm,
          timeInterval: this.timeInterval,
          asset: this.asset,
          capital: this.capital,
        }),
      });
      if (request.status === 200 || request.status === 401) {
        // const data = await this.parseData(request);
        //routing to dashboard or reloading page
        this.forwardRoute();
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);

        //TODO: create error response from the server msg
        console.log(data.msg);
        this.forwardRoute();
      }
    },
    async parseData(res) {
      const text = await res.text();
      const data = await JSON.parse(text);
      return data;
    },
    reloadPage() {
      window.location.reload();
    },
    forwardRoute() {
      console.log(this.$route.name);
      if (this.$route.name != "Dashboard") {
        this.$router.push("/dashboard");
      } else {
        this.reloadPage();
      }
    },
  },
  computed: {
    formattedDate() {
      return this.due ? format(parseISO(this.due), "d MMM yyyy") : "";
    },
    createBotURL() {
      const userId = this.$store.getters["user/getUser"].id;
      return `${process.env.VUE_APP_SINGLE_BOT_URL}${userId}`;
    },
  },
};
</script>

<style>
</style>