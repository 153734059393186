<template>
  <v-dialog max-width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn text color="error" class="mt-3" slot="activator" v-on="on">
        <span>Bot löschen</span>
        <v-icon right>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- The Deletion PopUp -->
    <v-card>
      <v-card-title>Bot löschen </v-card-title>

      <v-card-text>
        Sind sie sicher, dass sie diesen Bot löschen möchten?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="error" text @click="deleteBotSubmit">
          Ja, diesen Bot löschen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export default {
  name: "BotDeletePopUp",
  data() {
    return {
      dialog: false,
    };
  },
  props: ["botId"],
  methods: {
    async deleteBotSubmit() {
      //sending request to the server
      await this.postForm();
      //closing the Dialog
      this.dialog = false;
      this.$emit("botDeleted");
    },
    async postForm() {
      const botId = this.botId;
      const token = this.$store.getters["user/getTokenValue"];

      let request = await fetch(this.deleteBotURL, {
        method: "DELETE",
        withCredentials: true,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
        }),
      });
      if (request.status === 200 || request.status === 401) {
        // const data = await this.parseData(request);
        //routing to dashboard or reloading page
        this.forwardRoute();
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);

        //TODO: create error response from the server msg
        console.log(data.msg);
        this.forwardRoute();
      }
    },
    reloadPage() {
      window.location.reload();
    },
    forwardRoute() {
      console.log(this.$route.name);
      if (this.$route.name != "Bots") {
        this.$router.push("/bots");
      } else {
        this.reloadPage();
      }
    },
  },
  computed: {
    formattedDate() {
      return this.due ? format(parseISO(this.due), "d MMM yyyy") : "";
    },
    deleteBotURL() {
      const userId = this.$store.getters["user/getUser"].id;
      return `${process.env.VUE_APP_SINGLE_BOT_URL}${userId}`;
    },
  },
};
</script>

<style>
</style>