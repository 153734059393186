<template>
  <nav>
    <!-- snackbar -->
    <!-- Bot created -->
    <v-snackbar v-model="snackbar_created" :timeout="4000" top color="success">
      <span class="mr-3">Congratulations! You added a new Bot!</span>
      <v-btn text color="white" @click="snackbar_created = false">Close</v-btn>
    </v-snackbar>
    <!-- Bot deleted -->
    <v-snackbar v-model="snackbar_deleted" :timeout="4000" top color="warning">
      <span class="mr-3">Your Bot got deleted!</span>
      <v-btn text color="white" @click="snackbar_deleted = false">Close</v-btn>
    </v-snackbar>

    <v-app-bar flat app outlined>
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-app-bar-title class="text-uppercase grey--text">
        <span class="">Edgefy</span>
      </v-app-bar-title>
      <img src="/logo/logo.svg" alt="Edgefy" width="100" height="75" />
      <!-- Davor kommt alles nach links alles danach kommt nach rechts -->
      <v-spacer></v-spacer>
      <!-- dropdown menu -->
      <!--
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text slot="activator" color="grey" v-on="on">
            <v-icon left>mdi-chevron-down</v-icon>
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-title>
              {{ link.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      -->

      <v-btn text color="grey" @click="logout">
        <span>Sign Out</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- show the navigtaion draw -->
    <v-navigation-drawer v-model="drawer" app class="primary lighten-1">
      <v-layout column align-center>
        <v-flex class="mt-8">
          <v-avatar size="100">
            <img src="/img/pic-1.jpeg" alt="home person" />
          </v-avatar>
          <p class="white--text subheading mt-1">Josef Mayer</p>
        </v-flex>
        <v-flex class="mt-4 mb-5">
          <CreateBotPopup @botCreated="snackbar_created = true" />
        </v-flex>
      </v-layout>
      <v-list>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">mdi-{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="hide">
        <BotDeletePopUp @botDeleted="snackbar_deleted = true" />
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import CreateBotPopup from "./CreateBotPopup";
import BotDeletePopUp from "@/components/BotDeletePopUp";

export default {
  name: "Navbar",
  components: {
    CreateBotPopup,
    BotDeletePopUp,
  },
  data() {
    return {
      drawer: false,
      links: [
        { icon: "view-dashboard", text: "Dashboard", route: "/" },
        { icon: "robot-outline", text: "My Bots", route: "/bots" },
        { icon: "account-cog", text: "Settings", route: "/settings" },
        // { icon: "laptop", text: "Algorithms", route: "/algorithms" }
      ],
      snackbar_created: false,
      snackbar_deleted: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/auth/login");
    },
  },
};
</script>

<style>
.hide {
  display: none;
}
</style>